import "primeicons/primeicons.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/themes/tailwind-light/theme.css";
// import "primereact/resources/primereact.min.css";
import React from "react";
import "../styles/globals.css";
// import '../styles/firebaseui-styling.global.css'; // Import globally.
import initAuth from "../utils/initAuth";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { datadogRum } from "@datadog/browser-rum";
import { PrimeReactProvider } from "primereact/api";
import Tailwind from "primereact/passthrough/tailwind";
import { usePassThrough } from "primereact/passthrough";
// import SaveRouterHistory from "../components/SaveRouterHistory";

import OrgWrapper from "../components/OrgWrapper";

import { StrictMode } from "react";

initAuth();

if (process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID == "ranktivity-prod-45a98") {
  datadogRum.init({
    applicationId: "1417101f-bd0a-4ce1-aae8-fe0f9f2f797d",
    clientToken: "pub8be46951121907e34b2363cfe9e1f481",
    site: "datadoghq.com",
    service: "ranktivity-app",
    env: "prod",
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}

datadogRum.startSessionReplayRecording();

const MyApp = ({ Component, pageProps }) => {
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page);

  // https://medium.com/in-the-weeds/fetch-a-query-only-once-until-page-refresh-using-react-query-a333d00b86ff
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            retry: false,
            // staleTime: twentyFourHoursInMs,
          },
        },
      })
  );

  const CustomTailwind = usePassThrough(
    Tailwind,
    {
      selectbutton: {
        root: ({ props }) => ({
          className: classNames({
            "opacity-60 select-none pointer-events-none cursor-default":
              props.disabled,
          }),
        }),
        button: ({ context }) => ({
          className: classNames(
            "inline-flex cursor-pointer select-none items-center align-bottom text-center overflow-hidden relative",
            "px-4 py-3",
            "transition duration-200 border border-r-0",
            "first:rounded-l-md first:rounded-tr-none first:rounded-br-none last:border-r last:rounded-tl-none last:rounded-bl-none last:rounded-r-md",
            "focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]",
            {
              "bg-white dark:bg-gray-900 text-gray-700 dark:text-white/80 border-gray-300 dark:border-blue-900/40 hover:bg-gray-50 dark:hover:bg-gray-800/80 ":
                !context.selected,
              "bg-black bg-blue-500 border-blue-500 text-white hover:bg-blue-600":
                context.selected,
              "opacity-60 select-none pointer-events-none cursor-default":
                context.disabled,
            }
          ),
        }),
        label: "font-bold",
      },
    },
    {
      mergeSections: true,
      mergeProps: false,
    }
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading

    <StrictMode>
      <QueryClientProvider client={queryClient} contextSharing={true}>
      <OrgWrapper>
          <Hydrate state={pageProps.dehydratedState}>
            {" "}
            <PrimeReactProvider>
            {/* <SaveRouterHistory/> */}
              {getLayout(<Component {...pageProps} />)}{" "}
            </PrimeReactProvider>
          </Hydrate>{" "}
          <ReactQueryDevtools initialIsOpen={false} />{" "}
        </OrgWrapper>
      </QueryClientProvider>
    </StrictMode>
  );
};

export default MyApp;