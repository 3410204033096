import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useRouter } from "next/router";
import { withUser, useUser } from "next-firebase-auth";
import OrgContext from "./OrgContext";
import { useQuery, useQueryClient } from "react-query";

const OrgProvider = ({ children }) => {
  const [activeOrg, setActiveOrg] = useState(null);
  const AuthUser = useUser();
  const router = useRouter();
  const db = firebase.firestore();
  const queryClient = useQueryClient();

  // Check if AuthUser is still initializing or AuthUser.id is null
  const isAuthInitializing = !AuthUser || AuthUser.id === null;

  // Log initial AuthUser state for debugging
  useEffect(() => {
    console.log("AuthUser state:", AuthUser);
  }, [AuthUser]);

  // Fetch organizations using react-query
  const fetchOrganizations = async () => {
    const userId = AuthUser?.id;
    if (!userId) {
      console.warn("No AuthUser ID available when trying to fetch organizations.");
      return [];
    }
  
    try {
      const querySnapshot = await db
        .collection("memberships")
        .where("uid", "==", userId)
        .orderBy("created_at")
        .get();
  
      const orgIds = querySnapshot.docs.map((doc) => doc.data().orgId);
      console.log("Fetched orgIds:", orgIds);
  
      const orgPromises = orgIds.map((orgId) =>
        db.collection("orgs").doc(orgId).get()
      );
      const orgDocs = await Promise.all(orgPromises);
  
      let orgs = [];
      for (const doc of orgDocs) {
        let orgDocData = doc.data();
        let parentOrgId = orgDocData.parentOrgId || (orgDocData.primaryOrg ? doc.id : null);
  
        // Get the parent organization data
        let parentDocRef = await db.collection('orgs').doc(parentOrgId).get();
        let parentDocData = parentDocRef.data();
  
        const subDocRef = await db
          .collection("orgs")
          .doc(parentOrgId)
          .collection("subscriptions")
          .doc(parentDocData.subscriptionId)
          .get();
  
        // Query websites for this organization
        const websitesSnapshot = await db
          .collection("websites")
          .where("orgId", "==", doc.id)
          .get();
  
        const websites = websitesSnapshot.docs.map((websiteDoc) => websiteDoc.data());
  
        orgs.push({
          orgId: doc.id,
          ...orgDocData,
          websites, // Add the websites array here
          subscription: subDocRef.data(),
          planFeatures: parentDocData.planFeatures,
          parentOrgId: orgDocData.parentOrgId || (orgDocData.primaryOrg ? doc.id : null),
        });
      }
  
      // Log fetched organizations
      console.log("Fetched organizations:", orgs);
  
      return orgs;
    } catch (error) {
      console.error("Error fetching organizations:", error);
      throw error;
    }
  };
  

  const { data: orgs = [], isError, error } = useQuery(
    ["orgs", AuthUser?.id],
    fetchOrganizations,
    {
      enabled: !!AuthUser?.id, // Only run if AuthUser.id is available
    }
  );

  // Handle setting activeOrg after orgs are fetched and AuthUser.id is valid
  useEffect(() => {
    if (!isAuthInitializing && orgs.length > 0 && !activeOrg && AuthUser?.id) {
      console.log("Setting activeOrg from orgs after AuthUser.id is available:", orgs);
      const firstOrg = orgs[0];
      setActiveOrg(firstOrg);
      console.log("Active organization set:", firstOrg);
    }
  }, [orgs, activeOrg, isAuthInitializing, AuthUser?.id]);

  // Handle URL-based organization selection once AuthUser.id is available
  useEffect(() => {
    const orgIdFromUrl = router.query.oid;
    if (orgIdFromUrl && orgs.length > 0 && AuthUser?.id) {
      const foundOrg = orgs.find((org) => org.orgId === orgIdFromUrl);
      if (foundOrg) {
        setActiveOrg(foundOrg);
        console.log(`Active organization set from URL after AuthUser.id is available: ${orgIdFromUrl}`, foundOrg);
      } else {
        console.error(`Organization ID from URL (${orgIdFromUrl}) does not match user memberships.`);
      }
    }
  }, [router, orgs, AuthUser?.id]);

  const updateActiveOrg = (org) => {
    if (AuthUser?.id) {
      setActiveOrg(org);
      console.log("Active organization updated:", org);
      router.push(`/org/${org.orgId}/website`);
    } else {
      console.warn("Attempted to update activeOrg before AuthUser.id was available.");
    }
  };

  const invalidateOrgs = () => {
    if (AuthUser?.id) {
      queryClient.invalidateQueries(["orgs", AuthUser?.id]);
      console.log("Invalidated orgs cache.");
    } else {
      console.warn("Attempted to invalidate orgs cache before AuthUser.id was available.");
    }
  };

  if (isError) {
    return <div>Error loading organizations: {error.message}</div>;
  }

  if (isAuthInitializing || !orgs.length || !activeOrg) {
    return <div>Loading...</div>;  // Only render children once AuthUser, orgs, and activeOrg are ready
  }

  return (
    <OrgContext.Provider
      value={{
        orgs,
        activeOrg,
        updateActiveOrg,
        invalidateOrgs,
      }}
    >
      {children}
    </OrgContext.Provider>
  );
};

export default withUser()(OrgProvider);
